/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable function-paren-newline */
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { List, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import { UserBalance } from 'types/user-balance';
import { balanceConfig, TBalanceListItem } from './utils/balanceConfig';

import styles from './styles.module.scss';
import { getTicketsQuantity } from 'api/user';
import { TicketDoubleIcon } from 'assets/svg/TicketDoubleIcon';
import { ChangeUserBalanceModal } from 'components/Modals/ChangeUserBalanceModal';

const { Text } = Typography;

interface Props {
  data: UserBalance[];
  refetch: any;
  userId: string;
}

const BalanceItem = ({
  item,
  refetch,
}: {
  item: TBalanceListItem;
  refetch: any;
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const amount = new BigNumber(new BigNumber(item.amount).toFixed(8, 1))
    .decimalPlaces(8, 1)
    .toString();

  return (
    <>
      <div
        className={classNames(
          styles.balanceItem,
          item?.balanceId && item.key === 'tickets' ? styles.active : ''
        )}
        onClick={
          item.key !== 'tickets'
            ? () => setModalOpen(true)
            : () => setModalOpen(false)
        }
      >
        {/*{!item?.balanceId && item.key !== 'tickets' && (*/}
        {/*  <div className={styles.hide} />*/}
        {/*)}*/}
        <List.Item>
          <List.Item.Meta
            title={<Text>{item.label}</Text>}
            description={
              <Text
                ellipsis={{
                  tooltip: amount,
                }}
                style={{ width: 70, fontSize: 12 }}
              >
                <div>{amount}</div>
              </Text>
            }
            avatar={item.logo}
          />
          <List.Item.Meta title={<Text></Text>} />
        </List.Item>
      </div>
      <ChangeUserBalanceModal
        open={isModalOpen}
        refetch={refetch}
        item={item}
        closeHandler={() => setModalOpen(false)}
      />
    </>
  );
};

export const Balance: React.FC<Props> = ({ data, refetch, userId }) => {
  const [balanceList, setBalanceList] = useState<TBalanceListItem[]>([]);
  const getTickets = async () => {
    const items = balanceConfig(data);
    const ticketsQuantity = await getTicketsQuantity(userId);

    items.push({
      key: 'tickets',
      label: 'Tickets',
      logo: <TicketDoubleIcon />,
      amount: ticketsQuantity || 0,
      balanceId: '',
    });

    setBalanceList(items);
  };

  useEffect(() => {
    getTickets();
  }, [data, userId]);

  if (!data) return <Spin />;

  return (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={balanceList}
      grid={{
        column: 2,
        lg: 1,
        md: 1,
      }}
      renderItem={(item: TBalanceListItem) => (
        <BalanceItem item={item} refetch={refetch} />
      )}
    />
  );
};
